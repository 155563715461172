import { atom } from "jotai";
import { Project, ProjectSearchArgs } from "../api";
import { Option } from "am-tax-fe-core";
import { getLocalStorage, StorageKey } from "../util/local-storage-utils";

export const calendarListViewAtom = atom<boolean>(getLocalStorage<boolean>(StorageKey.CalendarView) ?? false);
export const calendarMonthsPerPageAtom = atom<number>(getLocalStorage<number>(StorageKey.CalendarNumberOfMonths) ?? 6);

export const clientIdAtom = atom<string | undefined>(undefined);
export const selectedIndexAtom = atom<number | undefined>(undefined);
export const isDeliverableRejectionModalOpenAtom = atom<boolean>(false);

export type ProjectSearchForm = ProjectSearchArgs & {
    leadMd?: Option<string>;
    leadDirector?: Option<string>;
};
export const projectSearchForm = atom<ProjectSearchForm>({});

export const projectsAtom = atom<Project[]>([]);
export const showEngagementShadeAtom = atom<boolean>(false);
