import { FunctionComponent, useMemo } from "react";

import useState from "react-usestateref";
import {
    AbsoluteCenter,
    Box,
    Button,
    Card,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    Spacer,
    Text,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DatePicker, dropTimeFromIsoFormat, FileContainer, RepeatShimmer, useConfirm, useToast } from "am-tax-fe-core";
import { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import {
    DeleteEngagementRequestArgs,
    DeleteProjectRequestArgs,
    Engagement,
    Participant,
    PostEngagementRequestArgs,
    PostProjectRequestArgs,
    Project,
    PutEngagementRequestArgs,
    PutProjectRequestArgs,
    Request,
    TreeNode,
    UploadDocumentsToEngagementRequestArgs,
    UploadDocumentsToProjectRequestArgs,
    User,
    useRequestStatuses,
    useRequestTypes,
} from "../../api";

import { accessMatchesIntentForRequest, AreaPermissions, canDelete, RequestArea, SecurityArea } from "../../auth";
import { UnauthorizedPage } from "../../pages/UnauthorizedPage";
import { RequestStatusEnum } from "../../util/RequestStatusEnum";
import { Mutation } from "../../util/queryUtils";

import { canCreateOrUpdateOrReassign, readonlyAccess } from "./request-form-utils";
import RenderReadOnly from "./RenderReadOnly";
import { TreeSelect } from "../TreeSelect";
import DocumentListPanel from "../DocumentListPanel";

export type ProjectEngagement = Project | Engagement;
export type SaveRequestArgs = PostEngagementRequestArgs | PutEngagementRequestArgs | PostProjectRequestArgs | PutProjectRequestArgs;
export type DeleteRequestArgs = DeleteEngagementRequestArgs | DeleteProjectRequestArgs;
export type UploadDocumentsToRequestArgs = UploadDocumentsToEngagementRequestArgs | UploadDocumentsToProjectRequestArgs;

export interface RequestFormProps {
    navigateBack?: () => void; // DEVNOTE: both useRequestFromForEngagement and useRequestFormForProject are omitting this method: Omit<RequestFormProps, "navigateBack">. It will always be null
    areaPermissions: AreaPermissions<SecurityArea>;
    projectEngagementQuery: UseQueryResult<ProjectEngagement>;
    requestQuery: UseQueryResult<Request>;
    participantsQuery: UseQueryResult<Participant[]>;
    folderStructureQuery: UseQueryResult<TreeNode[]>;
    saveRequestMutation: Mutation<SaveRequestArgs, Request>;
    deleteRequestMutation: Mutation<DeleteRequestArgs>;
    uploadFileToRequestMutation: Mutation<UploadDocumentsToRequestArgs>;
    onSaveComplete: (projectOrEngagement: ProjectEngagement, request: Request, filesUploaded?: boolean) => void;
}

export const RequestForm: FunctionComponent<RequestFormProps> = ({
    navigateBack,
    areaPermissions,
    projectEngagementQuery,
    requestQuery,
    participantsQuery,
    folderStructureQuery,
    saveRequestMutation,
    deleteRequestMutation,
    uploadFileToRequestMutation,
    onSaveComplete,
}) => {
    const toast = useToast();
    const { t } = useTranslation("RequestForm");

    const { engagementId, dueDate } = useParams();
    const [searchParams] = useSearchParams();
    const redirectToCalendar = searchParams.get("redirect") === "calendar";

    const [uploading, setUploading] = useState(false);
    const [fileQueue, setFileQueue] = useState<FileContainer[]>([]);
    const [, setNewRequestJustSaved, newRequestJustSavedRef] = useState<Request | undefined>(undefined);

    const requestStatusesQuery = useRequestStatuses();

    const requestTypesQuery = useRequestTypes();

    const projectOrEngagement = projectEngagementQuery.data;

    const folderStructure = folderStructureQuery.data;

    const request = requestQuery.data;
    const currentRequestId = request?.requestId || "";
    const currentFolderId = request?.folderId || projectOrEngagement?.clientDocumentsRootFolderId || "";
    const currentEngagementId = engagementId || "";

    const users: User[] = useMemo(() => {
        const participantList = participantsQuery.data;
        const users = participantList?.map(p => p.user) || [];
        if (request?.assignedTo && !participantList?.find(p => p.user.id === request.assignedTo?.id)) {
            users.push(request.assignedTo);
        }
        return users;
    }, [request, participantsQuery.data]);

    const goBack = () => {
        if (redirectToCalendar) {
            window.history.back();
        } else if (navigateBack) {
            navigateBack();
        } else {
            window.history.back();
        }
    };

    // ---- Code to Load the Form ----
    const formValues: Partial<SaveRequestArgs> = {
        title: request?.title,
        description: request?.description,
        dueDate: dropTimeFromIsoFormat(request?.dueDate) || dueDate || "",
        statusId: request?.status?.id || RequestStatusEnum.Requested,
        year: request?.year,
        assignedToId: request?.assignedTo?.id,
        requestType: request?.requestType.id,
        folderId: currentFolderId,
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<Partial<SaveRequestArgs>>({
        values: formValues,
    });

    const uploadFile = (fileContainer: FileContainer, requestId?: string | undefined) => {
        fileContainer.metaData = { folderId: currentFolderId };
        const args = uploadFileToRequestMutation.updateArgs({
            engagementId: currentEngagementId,
            requestId: requestId ? requestId : currentRequestId,
            fileContainer,
        });
        uploadFileToRequestMutation.query.mutate(args);
    };

    const onSubmit: SubmitHandler<Partial<SaveRequestArgs>> = async data => {
        const args = saveRequestMutation.updateArgs({
            requestId: request?.requestId ?? "",
            engagementId: engagementId ?? "",
            title: data.title!,
            dueDate: data.dueDate!,
            description: data.description,
            statusId: data.statusId!,
            year: data.year,
            assignedToId: data.assignedToId,
            requestType: data.requestType!,
            folderId: data.folderId!,
        });

        const response = await saveRequestMutation.query.mutateAsync(args);
        if (!request) {
            setNewRequestJustSaved(response);
        }

        if (fileQueue.length > 0) {
            setUploading(true);
            for (const fileContainer of fileQueue) {
                uploadFile(fileContainer, response.requestId);
            }
        } else {
            onSaveComplete(projectOrEngagement!, response as Request);
            toast({
                title: "Saved",
                description: "Request Saved.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            goBack();
        }
    };

    // ---- Setup the Delete Handler ----
    const { confirm, ConfirmDialog } = useConfirm({ title: t("confirmTitle"), prompt: t("prompt") });
    const doDelete = async () => {
        const result = await confirm();
        if (result) {
            const args = deleteRequestMutation.updateArgs({ requestId: currentRequestId, engagementId: engagementId ?? "" });
            deleteRequestMutation.query.mutate(args, {
                onSuccess: () => {
                    toast({
                        title: "Deleted",
                        description: "Request Deleted.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    goBack();
                },
            });
        }
    };

    const onUploadComplete = async () => {
        try {
            const currentRequest = request || newRequestJustSavedRef.current;
            onSaveComplete(projectOrEngagement!, currentRequest!, true);
            setFileQueue([]);
            toast({
                title: "Saved",
                description: "Saved Request and Uploaded Files.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            if (newRequestJustSavedRef.current) {
                setNewRequestJustSaved(undefined);
                goBack();
            }
        } finally {
            setUploading(false);
        }
    };

    if (
        !accessMatchesIntentForRequest(
            areaPermissions,
            RequestArea.create,
            RequestArea.read,
            RequestArea.update,
            RequestArea.delete,
            RequestArea.reassign,
            currentRequestId,
        )
    ) {
        return <UnauthorizedPage />;
    }

    // a list of years going seven years back and two yeas forward
    const startingYear = new Date().getFullYear() + 2;
    const years = Array.from({ length: 10 }, (_, i) => startingYear - i);

    if (requestQuery.isLoading || requestStatusesQuery.isLoading || requestTypesQuery.isLoading) {
        return (
            <VStack spacing="1px" alignItems="stretch">
                <RepeatShimmer times={5} height="40px" />
            </VStack>
        );
    }

    const isReadOnly: boolean = readonlyAccess(areaPermissions, RequestArea.update, RequestArea.reassign, RequestArea.read, currentRequestId);

    const canReadForm: boolean = areaPermissions.has(RequestArea.read);

    const canUpload: boolean = canCreateOrUpdateOrReassign(areaPermissions, RequestArea.create, RequestArea.update, RequestArea.reassign, currentRequestId);

    const canUpdateForm: boolean = areaPermissions.hasAny(RequestArea.update, RequestArea.reassign, RequestArea.create);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={saveRequestMutation.query.isPending || uploading}>
                    <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
                        {(canUpdateForm || canReadForm) && (
                            <FormControl isInvalid={!!errors?.title}>
                                <FormLabel>{t("formTitle")}</FormLabel>
                                <RenderReadOnly isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.title}</Text>}>
                                    <Input {...register("title", { required: t("titleError") })} />
                                </RenderReadOnly>

                                <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                            </FormControl>
                        )}

                        <FormControl isInvalid={!!errors?.assignedToId}>
                            <FormLabel>{t("assignedTo")}</FormLabel>
                            {canUpdateForm ? (
                                //placeholder={t("requestType")} {...register("requestType", { required: t("requestTypeError") })}
                                <Select {...register("assignedToId", { required: t("assigneeError") })} placeholder={"Select Assignee"}>
                                    {users?.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.firstName} {user.lastName}
                                        </option>
                                    ))}
                                </Select>
                            ) : (
                                <Text>
                                    {request?.assignedTo?.firstName} {request?.assignedTo?.lastName}
                                </Text>
                            )}
                            <FormErrorMessage>{errors?.assignedToId?.message}</FormErrorMessage>
                        </FormControl>
                    </SimpleGrid>

                    {(canUpdateForm || canReadForm) && (
                        <Box sx={{ my: 3 }}>
                            <FormControl isInvalid={!!errors?.description}>
                                <FormLabel>{t("description")}</FormLabel>
                                <RenderReadOnly isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.description}</Text>}>
                                    <Textarea {...register("description")} />
                                </RenderReadOnly>
                                <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                            </FormControl>
                        </Box>
                    )}

                    {(canUpdateForm || canReadForm) && (
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
                            <FormControl isInvalid={!!errors?.requestType}>
                                <FormLabel>Request Type:</FormLabel>
                                <RenderReadOnly isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.requestType.name || "DocumentRequest"}</Text>}>
                                    <Select placeholder={t("requestType")} {...register("requestType", { required: t("requestTypeError") })}>
                                        {requestTypesQuery.data?.map(requestType => (
                                            <option key={requestType.id} value={requestType.id}>
                                                {requestType.name}
                                            </option>
                                        ))}
                                    </Select>
                                </RenderReadOnly>
                                <FormErrorMessage>{errors?.requestType?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.dueDate}>
                                <FormLabel>{t("due")}</FormLabel>
                                <RenderReadOnly
                                    isReadOnly={isReadOnly}
                                    readOnlyValue={<Text>{dropTimeFromIsoFormat(request?.dueDate) || dueDate || undefined}</Text>}
                                >
                                    <DatePicker {...register("dueDate", { required: t("dueDateError") })} />
                                </RenderReadOnly>
                                <FormErrorMessage>{errors?.dueDate?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.statusId}>
                                <FormLabel>{t("status")}</FormLabel>
                                <RenderReadOnly isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.status?.name || RequestStatusEnum.Requested}</Text>}>
                                    <Select placeholder={t("statusPlaceholder")} {...register("statusId", { required: t("statusError") })}>
                                        {requestStatusesQuery.data?.map(status => (
                                            <option key={status.id} value={status.id}>
                                                {status.name}
                                            </option>
                                        ))}
                                    </Select>
                                </RenderReadOnly>
                                <FormErrorMessage>{errors?.statusId?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.year}>
                                <FormLabel>{t("year")}</FormLabel>
                                <RenderReadOnly isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.year ? request.year : undefined}</Text>}>
                                    <Select placeholder={t("yearPlaceholder")} {...register("year")}>
                                        {years.map(year => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </Select>
                                </RenderReadOnly>
                                <FormErrorMessage>{errors?.year?.message}</FormErrorMessage>
                            </FormControl>
                        </SimpleGrid>
                    )}

                    {(canUpdateForm || canReadForm) && (
                        <Box sx={{ my: 3 }}>
                            {folderStructure ? (
                                <FormControl isInvalid={!!errors?.folderId}>
                                    <FormLabel>Select folder for request files:</FormLabel>
                                    <Card sx={{ p: 2 }}>
                                        <Controller
                                            control={control}
                                            name="folderId"
                                            rules={{
                                                required: "Please select folder for request files",
                                            }}
                                            render={({ field: { onChange } }) => (
                                                <TreeSelect
                                                    defaultValue={currentFolderId}
                                                    nodes={folderStructure}
                                                    onSelectionChanged={onChange}
                                                    singleSelection={true}
                                                />
                                            )}
                                        ></Controller>
                                    </Card>
                                    <FormErrorMessage>{errors?.folderId?.message}</FormErrorMessage>
                                </FormControl>
                            ) : (
                                <RepeatShimmer times={5} height="10px" />
                            )}
                        </Box>
                    )}
                </fieldset>

                <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                    {canDelete(areaPermissions, RequestArea.delete, currentRequestId) && (
                        <Button
                            variant="ghost"
                            onClick={doDelete}
                            colorScheme={"red"}
                            fontWeight={200}
                            isDisabled={uploading || saveRequestMutation.query.isPending}
                            isLoading={deleteRequestMutation.query.isPending}
                            loadingText={t("deleting")}
                        >
                            {t("deleteRequest")}
                        </Button>
                    )}
                    <Spacer />
                    {canUpload && (
                        <Button
                            type="submit"
                            variant={"primary"}
                            mr={3}
                            isLoading={uploading || saveRequestMutation.query.isPending}
                            isDisabled={saveRequestMutation.query.isPending}
                            loadingText={t("saving")}
                        >
                            {t("save")}
                        </Button>
                    )}
                    <Button variant="ghost" onClick={goBack}>
                        {t("cancel")}
                    </Button>
                </Flex>
            </form>

            <Box sx={{ position: "relative", py: 10 }}>
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                    <Text fontSize="sm">Request Files</Text>
                </AbsoluteCenter>
            </Box>

            <DocumentListPanel
                engagementId={currentEngagementId}
                documents={request?.documents}
                immediateUpload={!!currentRequestId} // if we've already saved the request, upload the file immediately
                fileQueue={fileQueue}
                setFileQueue={setFileQueue}
                uploadFile={uploadFile}
                onUploadComplete={onUploadComplete}
                canUpload={canUpload}
            />
            <ConfirmDialog />
        </>
    );
};
