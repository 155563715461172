export enum StorageKey {
    CalendarNumberOfMonths,
    CalendarView,
}

export function setLocalStorage<T>(key: StorageKey, value: T): void {
    if (typeof value === "object") {
        localStorage.setItem(key.toString(), JSON.stringify(value));
    } else {
        localStorage.setItem(key.toString(), value as string);
    }
}

export function getLocalStorage<T>(key: StorageKey): T | undefined {
    const item = localStorage.getItem(key.toString());

    if (!item) {
        return undefined;
    }

    try {
        return JSON.parse(item) as T;
    } catch {
        return item as unknown as T;
    }
}
