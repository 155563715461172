import { FunctionComponent } from "react";

import { FileContainer, FileUploader } from "am-tax-fe-core";
import { Alert, AlertDescription, AlertIcon, Box, Flex } from "@chakra-ui/react";

export interface Props {
    immediateUpload?: boolean;
    fileQueue: FileContainer[];
    setFileQueue: (q: FileContainer[]) => void;
    uploadFile: (q: FileContainer) => void;
    onUploadComplete: () => void;
}

export const UploadPanel: FunctionComponent<Props> = ({ immediateUpload, fileQueue, setFileQueue, uploadFile, onUploadComplete }) => {
    const onFileAdded = async (fileContainer: FileContainer) => {
        if (immediateUpload) {
            uploadFile(fileContainer);
        } else {
            const queue = [...fileQueue];
            queue.push(fileContainer);
            setFileQueue(queue);
        }
    };
    const onFileCancelled = async (fileContainer: FileContainer) => {
        const updatedQueue = [];
        const queue = [...fileQueue];
        for (const file of queue) {
            if (file.index !== fileContainer.index) {
                updatedQueue.push(file);
            }
        }
        setFileQueue(updatedQueue);
    };

    return (
        <>
            <Alert mt="2rem" mb="1rem" status="info" variant="subtle" flexDirection="column">
                <Flex ml="1rem" alignItems="center" width="100%">
                    <AlertIcon boxSize="20px" mr="1rem" />
                    <Box>
                        <AlertDescription textAlign="left">
                            Files larger than 300 MB may take longer to upload. Please remain on the page until the upload is complete.
                        </AlertDescription>
                    </Box>
                </Flex>
            </Alert>
            <FileUploader
                maxSize={1073741824}
                flattenFolders={true}
                onFileAdded={onFileAdded}
                onFileCancelled={onFileCancelled}
                onBatchUploaded={onUploadComplete}
            />
        </>
    );
};
